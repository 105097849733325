
function validaForm(form){
	var required = $("#"+form + "  .required");
	var req = 0;
	for(i=0;i<required.length;i++){
		if($(required[i]).val() == ""){
			var classe = $(required[i]).attr('class');
			
			if(classe.search("required") != -1){	
				$(required[i]).addClass('erroInput');
			}
			req = 1;
		}else{
			var classe = $(required[i]).attr('class');
			
			if(classe.search("erroInput") != -1){
				$(required[i]).removeClass('erroInput');
			}
		}
	}
	
	if(req == 1){
		// alert("Preencha os campos obrigatórios.");
		$('#info-campos-obrigatorios').modal({show:true});
		return false;
	}else if(req == 0){
		return true;
	}
}


function openProduto(id){
	$('#produto-modal').modal({show:true});
	$.ajax({
		url: 'views/modal-download.php?id='+id,
		type: 'POST',
		data: '',
		cache: false,
		success: function(html) {
			$('#content-produto').html(html);
		}
	});
}

function openLogin(id){
	$('#login-modal').modal({show:true});
	$.ajax({
		url: 'views/modal-login.php',
		type: 'POST',
		data: '',
		cache: false,
		success: function(html) {
			$('#content-login').html(html);
		}
	});
}

function trim(str) {
    var str = str.replace(/^\s\s*/, ''),
                  ws = /\s/,
		  i = str.length;
    while (ws.test(str.charAt(--i)));
    return str.slice(0, i + 1);
}


function Mascara2(campo,mask,evt,proximo){
	var tecla;
	
    if (navigator.product == 'Gecko')
        tecla = evt.which;
    else
        tecla = window.event.keyCode;
    if (tecla == 13){
        if (proximo != "")
            $(proximo).focus();
        evt.cancelBubble = true;
        evt.returnValue = false;
    }
    else 
    if (((tecla < 48)||(tecla > 57))&&(tecla != 8)&&(tecla != 0))
        return false;
    else
    if ((mask != "")&&(tecla != 8)&&(tecla != 0)){
		var i = campo.value.length;
        var saida = mask.substring(0,1);
        var texto = mask.substring(i)
        if (texto.substring(0,1) != saida)
            campo.value += texto.substring(0,1);
    }
}

function SomenteNumero(e){
    var tecla=(window.event)?event.keyCode:e.which;   
    if((tecla>47 && tecla<58)) return true;
    else{
    	if (tecla==8 || tecla==0) return true;
	else  return false;
    }
}

function SomenteLetras(e){
    var tecla=(window.event)?event.keyCode:e.which; 
    if((tecla<47 || tecla>58)) {
		return true;
	} else {
		return false;
    }
}

function checkMail(email){
	var er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
	if(typeof(email) == "string"){
		if(er.test(email)){ return true; }
	}else if(typeof(email) == "object"){
		if(er.test(email.value)){ 
			return true; 
		}
		}else{
		return false;
	}
}

function validaEmail(campo, lang){
	if(campo.value != "E-mail *" && campo.value != "E-mail"){
		var email = campo;
		if (!checkMail(email) && campo.value != '') {
			email.value = '';
			email.focus;
			$('#modalEmailInvalido').modal({show:true});
		}
	}
}

function Mascara(tipo, campo, teclaPress) {
	if (window.event)
	{
		var tecla = teclaPress.keyCode;
	} else {
		tecla = teclaPress.which;
	}
 
	var s = new String(campo.value);
	// Remove todos os caracteres à seguir: ( ) / - . e espaço, para tratar a string denovo.
	s = s.replace(/(\.|\(|\)|\/|\-| )+/g,'');
 
	tam = s.length + 1;
 
	if ( tecla != 9 && tecla != 8 ) {
		switch (tipo)
		{	
		case 'TEL' :
           
            if (tam > 2 && tam < 4)
                campo.value = '(' + s.substr(0,2) + ') ' + s.substr(2,tam);
            if (tam >= 8 && tam < 12)
                campo.value = '(' + s.substr(0,2) + ') ' + s.substr(2,5) + '-' + s.substr(7,tam-7);
        break;
 
		
		case 'CEP' :
			if (tam > 5 && tam < 9)
				campo.value = s.substr(0,5) + '-' + s.substr(5, tam);
			
		break;
		
		case 'DATA' :
			if (tam > 2 && tam < 4)
				campo.value = s.substr(0,2) + '/' + s.substr(2, tam);
			if (tam > 4 && tam < 11)
				campo.value = s.substr(0,2) + '/' + s.substr(2,2) + '/' + s.substr(4,tam-4);
		break;
		
		case 'CPF' :
			if (tam > 3 && tam < 7)
				campo.value = s.substr(0,3) + '.' + s.substr(3, tam);
			if (tam >= 7 && tam < 10)
				campo.value = s.substr(0,3) + '.' + s.substr(3,3) + '.' + s.substr(6,tam-6);
			if (tam >= 10 && tam < 12)
				campo.value = s.substr(0,3) + '.' + s.substr(3,3) + '.' + s.substr(6,3) + '-' + s.substr(9,tam-9);
		break;
 
		case 'CNPJ' :
 
			if (tam > 2 && tam < 6)
				campo.value = s.substr(0,2) + '.' + s.substr(2, tam);
			if (tam >= 6 && tam < 9)
				campo.value = s.substr(0,2) + '.' + s.substr(2,3) + '.' + s.substr(5,tam-5);
			if (tam >= 9 && tam < 13)
				campo.value = s.substr(0,2) + '.' + s.substr(2,3) + '.' + s.substr(5,3) + '/' + s.substr(8,tam-8);
			if (tam >= 13 && tam < 15)
				campo.value = s.substr(0,2) + '.' + s.substr(2,3) + '.' + s.substr(5,3) + '/' + s.substr(8,4)+ '-' + s.substr(12,tam-12);
		break;
		
		case 'INSCRI' :
 
			if (tam > 2 && tam < 7)
				campo.value = s.substr(0,3) + '.' + s.substr(3, tam);
			if (tam >= 7 && tam < 10)
				campo.value = s.substr(0,3) + '.' + s.substr(3,3) + '.' + s.substr(5,tam-5);
			if (tam >= 9 && tam < 13)
				campo.value = s.substr(0,3) + '.' + s.substr(3,3) + '.' + s.substr(5,3) + '.' + s.substr(8,tam-8);
		break;
 

		}
	}
}